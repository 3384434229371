<script setup lang="ts">
/* eslint-disable no-underscore-dangle, @typescript-eslint/no-explicit-any */
import { ref, onMounted } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { AppButton } from '@mogelijk-technologies/ui-library';

interface BeforeInstallPromptEvent extends Event {
  prompt: () => Promise<{ outcome: 'accepted' | 'dismissed' }>;
  userChoice: Promise<{ outcome: 'accepted' | 'dismissed' }>;
}

const isPopupExpired = () => {
  const data = JSON.parse(localStorage.getItem('pwa-popup-dismissed') || 'null');

  if (!data) return true;
  const savedTimestamp = data.timestamp;
  const now = Date.now();

  const diffInDays = (now - savedTimestamp) / (1000 * 60 * 60 * 24);
  return diffInDays > 3; // after 3 days
};

const isIos = ref(false);
const isInstalled = ref(false);
const showPopup = ref(isPopupExpired());
const deferredPrompt = ref<BeforeInstallPromptEvent | null>(null);

onMounted(() => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  isIos.value = /iphone|ipad|ipod/.test(userAgent);
  isInstalled.value = window.matchMedia('(display-mode: standalone)').matches || ('standalone' in navigator && navigator.standalone) as boolean;

  if (isInstalled.value) {
    showPopup.value = false;
    return;
  }

  (window as any).__mogelijk__.beforeInstall((event: Event) => {
    if (isPopupExpired()) {
      deferredPrompt.value = event as BeforeInstallPromptEvent;
      showPopup.value = true;
    }
  });

  if (isIos.value && !('standalone' in navigator && navigator.standalone) && isPopupExpired()) {
    showPopup.value = true;
  }
});

const closePopup = () => {
  localStorage.setItem('pwa-popup-dismissed', JSON.stringify({ timestamp: Date.now() }));
  showPopup.value = false;
};

const installAndroid = async () => {
  if (deferredPrompt.value) {
    deferredPrompt.value.prompt();
    const { outcome } = await deferredPrompt.value.userChoice;

    if (outcome === 'accepted') {
      closePopup();
    }
    deferredPrompt.value = null;
  }
};
</script>

<template>
  <div v-if="showPopup" class="fixed inset-0 flex items-end justify-center bg-black/50 z-[200]">
    <div class="bg-white p-6 rounded-xl shadow-lg max-w-sm mx-4 mb-4">
      <div class="rounded-xl border border-gray-100 size-16 flex items-center justify-center justify-self-start mb-4">
        <img
          src="/android-chrome-192x192.png"
          alt="logo"
          class="size-12 block"
        />
      </div>
      <h2 class="text-lg font-semibold text-blue-900">Installeer de Mijn Mogelijk App</h2>
      <p class="text-gray-900 mt-2">
        {{ isIos
          ? 'Installeer de app op uw iPhone, dan heeft u snel en makkelijk toegang.'
          : 'Wilt u deze app installeren op uw telefoon?'
        }}
      </p>

      <div v-if="isIos" class="mt-4 space-y-2 text-gray-900 flex flex-col gap-2">
        <div class="flex flex-row gap-2 items-center">
          <div class="size-5 rounded-full flex items-center justify-center bg-blue-300">
            <p class="text-blue-600 text-center self-center text-sm font-semibold">1</p>
          </div>
          Klik hieronder op de
          <span class="text-blue-900">
            <FontAwesomeIcon :icon="['fas', 'arrow-up-from-square']" size="1x" class="text-xl px-1" />
          </span>knop.
        </div>
        <div class="flex flex-row gap-2 items-center">
          <div class="size-5 rounded-full flex items-center justify-center bg-blue-300">
            <p class="text-blue-600 text-center self-center text-sm font-semibold">2</p>
          </div>
          Kies voor
          <span class="font-semibold">
            Zet op beginscherm.
          </span>
        </div>
        <div class="flex flex-row gap-2 items-center">
          <div class="size-5 rounded-full flex items-center justify-center bg-blue-300">
            <p class="text-blue-600 text-center self-center text-sm font-semibold">3</p>
          </div>
          Klik vervolgens op
          <span class="font-semibold">
            Voeg toe.
          </span>
        </div>
        <p class="mt-2 font-semi-bold">Nu ziet u de app als icoon op uw startscherm.</p>
      </div>

      <div v-else class="mt-4 flex flex-col gap-4">
        <AppButton class="w-full bg-green-700 text-white !rounded-full py-1" @click="installAndroid">
          Installeren
        </AppButton>
        <AppButton
          class="w-full bg-gray-300 text-green-700 !rounded-full border border-green-700 py-1"
          @click="closePopup"
        >
          Annuleren
        </AppButton>
      </div>

      <AppButton
        v-if="isIos"
        class="mt-4 w-full bg-gray-300 text-green-700 !rounded-full border border-green-700 py-1"
        @click="closePopup"
      >
        Sluiten
      </AppButton>
    </div>
  </div>
</template>
