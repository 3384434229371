<script setup lang="ts">
import { AppTimer } from '@mogelijk-technologies/ui-library';
import MyAccount from '@components/Account/MyAccount.vue';

import type { RouteLocationRaw } from 'vue-router';

const config = useRuntimeConfig().public;

interface Props {
  title: string;
  showTimer?: boolean;
  notificationCount: number;
  backButtonLink?: RouteLocationRaw;
}

interface Emits {
  (event: 'setMenuVisible'): void;
}

withDefaults(defineProps<Props>(), {
  showTimer: false,
  backButtonLink: undefined,
});

const emit = defineEmits<Emits>();

const isMobile = ref(window.innerWidth < 768);

const scrollHeight = ref(0);
const mogelijkVastgoed = 'Mogelijk vastgoed';

const updateScrollY = () => {
  scrollHeight.value = window.scrollY;
};

onMounted(() => {
  window.addEventListener('scroll', updateScrollY);
  window.addEventListener('resize', () => {
    isMobile.value = window.innerWidth < 768;
  });
});

onUnmounted(() => {
  window.removeEventListener('scroll', updateScrollY);
});
</script>

<template>
  <header class="sticky top-0 z-40 bg-white tablet:!bg-gray-50 py-2 tablet:mb-2">
    <div class="m-auto flex w-full items-center justify-between self-start px-4 3xl:max-w-screen-3xl">
      <div
        class="ease relative flex min-w-60 items-center transition-all duration-300"
      >
        <NuxtLink
          :to="backButtonLink && isMobile ? backButtonLink : '/'"
          class="flex items-center tablet:mb-2"
          data-test-id="logo-home-button"
        >
          <span
            v-if="backButtonLink && isMobile"
            class="flex justify-center items-center size-8 tablet:w-12"
          >
            <FontAwesomeIcon
              :icon="['fa', 'arrow-left']"
              class="text-blue-700"
              size="lg"
            />
          </span>
          <img
            v-else
            src="/android-chrome-192x192.png"
            :alt="mogelijkVastgoed"
            :title="mogelijkVastgoed"
            class="w-8 tablet:w-12 block"
          />
          <img
            src="/mogelijk-logo-text.svg"
            :alt="mogelijkVastgoed"
            width="160"
            class="ml-2 mt-1 hidden w-28 overflow-hidden tablet:block"
            :title="mogelijkVastgoed"
          />
        </NuxtLink>
        <p class="truncate ml-2 pr-2 font-semibold tablet:hidden">{{ title }}</p>
      </div>
      <div class="relative">
        <div
          v-if="notificationCount > 0"
          class="absolute z-[21] top-1/2 -mt-3 -left-3 -translate-y-1/2 bg-orange-900 rounded-full flex place-items-center text-white size-6 tablet:hidden"
          @click="emit('setMenuVisible')"
        >
          <p class="text-center h-fit w-full">{{ notificationCount }}</p>
        </div>
        <slot name="hamburger" />
      </div>
      <div class="hidden gap-20 tablet:flex">
        <div
          v-if="showTimer"
          class="flex w-52 items-center rounded-md p-1"
          data-v-step="1"
        >
          <AppTimer :url="config.sseHost" />
        </div>
        <div class="flex items-center rounded-3xl">
          <MyAccount button-id="my-account-button" />
        </div>
      </div>
    </div>
  </header>
  <div
    v-if="showTimer"
    class="mb-4 bg-blue-100 py-6 px-4 tablet:hidden"
    data-v-step-mobile="1"
  >
    <p class="text-sm mb-2 text-blue-700">De dinsdag teller</p>
    <AppTimer :url="config.sseHost" />
  </div>
</template>

<style>
  #mogelijk__ui-library .menu-button > div {
    border-bottom: none;
  }
</style>
