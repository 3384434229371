<!-- eslint-disable tailwindcss/no-custom-classname -->
<script setup lang="ts">
import { computed, ref, toRefs } from 'vue';
import LegalEntitySelect from '@components/LegalEntitySelect/LegalEntitySelect.vue';

import { useMutation } from '@urql/vue';
import { useRouter } from 'vue-router';
import { AppImage, AppButton } from '@mogelijk-technologies/ui-library';
import { onClickOutside } from '@vueuse/core';
import { isIosOrAndroid } from '@helpers/mobile';
import { useAuth } from '@hooks/useAuth';
import { useAuthStore } from '@stores/useAuthStore';
import { LOGOUT_MUTATION } from '@graphql/mutations/auth';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import type { Account } from '@graphql/types/auth';

export interface Props {
  buttonId: string;
}

defineProps<Props>();

const router = useRouter();

const isMenuOpen = ref(false);
const myAccount = ref(null);
const { accounts, activeFunderId, authenticated, selectedAccount, user, isTourActive } = toRefs(useAuthStore());

const sortedAccounts = computed(() => Object.values(accounts.value).sort((left: Account, right: Account) => {
  if (left.last_login && right.last_login) {
    return left.last_login > right.last_login ? -1 : 1;
  }

  return 0;
}));

const privateGroup = {
  value: 0,
  name: 'Privé',
  icon: 'user',
};

const funderGroups = computed(() => {
  return user.value?.funders?.length ? {
    type: 'group',
    label: 'Mijn bedrijf',
    icon: 'buildings',
    options: [
      ...user.value.funders.map(funder => ({
        type: 'option',
        value: funder.id as number,
        name: funder.name,
      })),
    ],
  } : {
    options: [],
  };
});

const activeFunder = computed(() => [
  privateGroup,
  ...funderGroups.value.options,
].find(item => item?.value === activeFunderId.value as number));

const { logout } = useAuth();
const logoutMutation = useMutation<undefined>(LOGOUT_MUTATION);
const handleLogout = () => logout(logoutMutation);

const selectAccount = (email: string | null) => {
  selectedAccount.value = email;
  router.push('/login');
};

onMounted(() => {
  onClickOutside(myAccount, () => {
    if (isTourActive.value) return;
    isMenuOpen.value = false;
  });
});
</script>

<template>
  <div ref="myAccount" class="flex w-full flex-col tablet:w-80">
    <div class="flex w-full justify-between gap-8 rounded-t-2xl p-6" :class="{ 'bg-white': isMenuOpen }">
      <div class="flex w-full flex-row items-center gap-4">
        <div v-if="!authenticated" class="flex w-full flex-col tablet:items-end">
          <template v-if="isIosOrAndroid() && Object.keys(accounts).length > 0">
            <h2 class="mt-4 mb-6 text-lg sm:text-2xl md:text-3xl text-center leading-tight">Selecteer een account</h2>
            <div data-test-id="login-accounts-wrapper" class="flex flex-col gap-4 overflow-hidden">
              <button
                v-for="account in sortedAccounts"
                :key="account.id"
                class="flex cursor-pointer select-none items-center gap-4 text-left bg-transparent p-0"
                @click="() => selectAccount(account.email)"
              >
                <div class="aspect-square size-12 shrink-0 grow-0 overflow-hidden rounded-full bg-blue-50">
                  <AppImage :source="account.avatar_url" alt="avatar" />
                </div>
                <div class="flex flex-col justify-center overflow-hidden">
                  <h3 data-test-id="login-select-account-h3" class="truncate">
                    {{ account.name ? account.name : account.email }}
                  </h3>
                  <span v-if="account.name" class="truncate text-sm">{{ account.email }}</span>
                </div>
              </button>

              <button
                data-test-id="login-with-other-account"
                class="flex cursor-pointer select-none items-center gap-4 text-left bg-transparent p-0"
                @click="() => selectAccount(null)"
              >
                <div class="aspect-square size-12 shrink-0 grow-0 overflow-hidden rounded-full bg-blue-50">
                  <AppImage alt="avatar" />
                </div>
                <h3>Inloggen met een ander account</h3>
              </button>
            </div>
          </template>
          <template v-else>
            <NuxtLink to="/login" class="flex w-auto items-center gap-4 text-blue-900">
              <div class="flex size-12 items-center justify-center overflow-hidden rounded-full bg-blue-300 focus:outline-none">
                <FontAwesomeIcon :icon="['fad', 'user']" class="text-2xl" />
              </div>
              <div class="tablet:hidden">
                <p class="text-sm font-semibold">
                  Welkom
                </p>
                <p class="text-sm">
                  Gast gebruiker
                </p>
              </div>
              <div class="hidden tablet:flex">
                Inloggen
              </div>
            </NuxtLink>
            <NuxtLink
              to="/login"
              class="text-sm"
              title="Inloggen"
              data-test-id="myaccount-login-button"
            >
              <AppButton
                type="secondary"
                color="blue"
                class="!mt-4 tablet:hidden w-full"
              >
                Login
                <template #icon-end>
                  <FontAwesomeIcon
                    :icon="['fas', 'right-to-bracket']"
                  />
                </template>
              </AppButton>
            </NuxtLink>
          </template>
        </div>
        <div v-else class="flex w-full gap-1 flex-wrap 2xs:!flex-nowrap">
          <div class="flex gap-1 grow overflow-hidden">
            <div class="mr-3 flex size-12 shrink-0 items-center justify-center overflow-hidden rounded-full bg-blue-300 focus:outline-none">
              <AppImage v-if="user?.avatar_url" :source="user.avatar_url" class="size-12" />
              <FontAwesomeIcon v-else :icon="['fad', 'user']" class="text-2xl" />
            </div>
            <div class="flex shrink grow flex-col justify-center overflow-hidden">
              <p class="text-sm truncate font-semibold">
                {{ user?.name }}
              </p>
              <p class="text-sm truncate">
                {{ activeFunder?.name ?? 'Privé' }}
              </p>
            </div>
          </div>
          <div class="flex justify-end w-full -order-1 2xs:!w-auto 2xs:!shrink 2xs:!order-3">
            <AppButton
              v-if="user"
              :id="buttonId"
              data-v-step-button="3"
              data-test-id="toggle-my-account-menu"
              type="secondary"
              color="blue"
              is-icon
              class="size-8 p-2"
              @click="isMenuOpen = !isMenuOpen"
            >
              <FontAwesomeIcon
                :icon="['fas', 'chevron-down']"
                :class="{ 'rotate-180': isMenuOpen }"
              />
            </AppButton>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isMenuOpen" class="relative flex w-full flex-col">
      <div class="top-0 flex w-full flex-col gap-2 rounded-b-3xl bg-white p-6 !pt-0 tablet:shadow-md tablet:absolute">
        <div class="rounded-md" data-v-step="2">
          <LegalEntitySelect label="Ik investeer vanuit" />
        </div>
        <AppButton data-test-id="myaccount-logout-button" type="tertiary" @click.prevent="handleLogout">
          <FontAwesomeIcon :icon="['fas', 'arrow-right-from-bracket']" class="text-base" />
          <span class="ml-2 text-base font-semibold">Uitloggen</span>
        </AppButton>
      </div>
    </div>
  </div>
</template>
