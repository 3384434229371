<script setup lang="ts">
import { ComputedRef } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import MogelijkLogo from '@public/mogelijk-wit.svg';

export interface NavigationLink {
  to: string;
  label: string;
  icon: string;
  notificationCount?: ComputedRef<number | undefined>;
}

export interface NavigationGroup {
  label: string;
  links: NavigationLink[];
}

export interface Props {
  items: NavigationGroup[];
  route: string;
  isMenuOpenMobile: boolean;
  matchExactRoute?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  matchExactRoute: false,
  subItems: () => [],
});

interface Emits {
  (event: 'to', value: string): void;
  (event: 'openOrCloseMenu', value: boolean): void;
  (event: 'logout'): void;
}
const emit = defineEmits<Emits>();

const isActiveRoute = (item: NavigationLink): boolean => {
  return props.matchExactRoute ? props.route === item.to : props.route.includes(item.to);
};

const getIcon = (item: NavigationLink): string[] => [isActiveRoute(item) ? 'fas' : 'fal', item.icon];
</script>

<template>
  <div>
    <div
      id="open-hamburger-menu"
      class="sticky overflow-y-hidden inset-0 z-80 lg:hidden"
      @click="emit('openOrCloseMenu', true)"
    >
      <FontAwesomeIcon :icon="['fad', 'bars']" size="xl" />
    </div>
    <div
      class="z-40 flex flex-col overflow-auto overflow-x-hidden fixed top-0 left-0 w-screen max-h-dvh touch-auto"
      :class="{'!hidden lg:flex': !isMenuOpenMobile}"
    >
      <section class="bg-gradient-to-tr from-blue-500 to-blue-900 px-4 pt-2 pb-8 grow">
        <div class="flex justify-between items-center pb-8">
          <img :src="MogelijkLogo" alt="logo" class="size-8" />
          <div id="close-hamburger-menu" class="text-white" @click="emit('openOrCloseMenu', false)">
            <FontAwesomeIcon :icon="['fas', 'xmark']" size="2x" />
          </div>
        </div>

        <slot />

        <nav class="flex flex-col gap-10 w-full 2xs:px-5">
          <ul v-for="item in items" :key="item.label" class="flex flex-col gap-2">
            <p class="text-gray-100 text-sm font-semibold">{{ item.label }}</p>
            <li
              v-for="(link, index) in item.links"
              :key="link.label"
              class="z-[9] flex flex-row gap-4 text-white relative cursor-pointer py-2 items-center lg:border-none"
              :class="{
                'before:bg-black before:h-full before:w-screen before:font-bold font-bold before:absolute before:opacity-20 before:-left-9'
                  : isActiveRoute(link),
              }"
              :data-test-id="`mobile-menu-link-${index.toString()}`"
              :title="link.label"
              @click.prevent="emit('to', link.to)"
            >
              <FontAwesomeIcon
                class="relative z-10 h-auto w-5"
                :icon="getIcon(link)"
              />
              <div
                v-if="link.notificationCount?.value && link.notificationCount.value > 0"
                class="absolute top-1/2 -mt-3 -left-3 -translate-y-1/2 z-10 bg-orange-900 rounded-full flex
                place-items-center text-white size-6"
              >
                <p class="text-center h-fit w-full">{{ link.notificationCount }}</p>
              </div>

              <span class="relative text-base z-20 w-auto">
                {{ link.label }}
              </span>
            </li>
          </ul>
        </nav>
      </section>
      <section class="empty:hidden bg-blue-50 py-10 px-4 2xs:!px-9">
        <slot name="submenu" />
      </section>
    </div>
  </div>
</template>
