<script setup lang="ts">
/* eslint-disable @typescript-eslint/no-unused-vars */
import PageHeader from '@components/PageHeader/PageHeader.vue';
import MyAccount from '@components/Account/MyAccount.vue';
import OnboardingBanner from '@components/Onboarding/OnboardingBanner.vue';
import OfferInfo from '@components/ModalContent/OfferInfo.vue';
import MobileMenu from '@components/MobileMenu.vue';
import InstallPwaPopup from '@components/InstallPwaPopup.vue';

import { useInvestorTestStore } from '@stores/useInvestorTestStore';
import { ref, computed, ComputedRef } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useModal, SidebarMenu, CallMeBack, useToast } from '@mogelijk-technologies/ui-library';
import { useAuthStore } from '@stores/useAuthStore';
import { CallBack } from '@components/Contact/';
import { CREATE_CALLBACK } from '@graphql/mutations/callback';
import { useMutation } from '@urql/vue';
import { isIosOrAndroid, isIosOrAndroidButNotPwa } from '@helpers/mobile';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { PersonRoleTypeEnum } from '@graphql/enums/auth';

import type { RouteLocationRaw } from 'vue-router';

interface Props {
  title: string;
  showTimer?: boolean;
  backButtonLink?: RouteLocationRaw;
}

withDefaults(defineProps<Props>(), {
  showTimer: false,
  backButtonLink: undefined,
});

const route = useRoute();
const router = useRouter();

const {
  authenticated,
  isInvestor,
  isFundInvestor,
  user,
  isVisibleForInvestorInApp,
  isVisibleForBorrowerInApp,
  isLoading,
} = toRefs(useAuthStore());

const { logout, getPersonRole, getUserPhoneNumbers } = useAuthStore();

const {
  isProfessionalismTestInExperienced,
  isProfessionalismTestRejected,
  isProfessionalismTestPendingRequest,
} = toRefs(useInvestorTestStore());

interface NavigationLink {
  to: string;
  label: string;
  icon: string;
  notificationCount?: ComputedRef<number | undefined>;
}

const isMobileMenuVisible = ref(false);

const canBypassMobileChecks = computed(() => (user.value?.bypass_checks && window.innerWidth < 500 && isVisibleForInvestorInApp.value));

const investorLinks = computed(() => {
  // added to solve issue where computed is not triggered on time
  const isMobileMenuOpen = isMobileMenuVisible.value;
  const links: NavigationLink[] = [
    {
      to: '/aanbod',
      label: 'Actueel aanbod',
      icon: 'grid-2',
    },
  ];

  if ((isIosOrAndroid() && getPersonRole(PersonRoleTypeEnum.INVESTOR)) || (user.value?.bypass_checks && window.innerWidth < 500)) {
    links.push({
      to: '/claims-en-opties',
      label: 'Claims & opties',
      icon: 'files',
    });
  }

  if ((isIosOrAndroid() && isVisibleForInvestorInApp.value) || canBypassMobileChecks.value) {
    links.push(
      {
        to: '/investeringen',
        label: 'Investeringen',
        icon: 'chart-mixed',
      },
      {
        to: '/financieel-overzicht',
        label: 'Financieel overzicht',
        icon: 'chart-line-up',
      },
    );
  }

  return {
    label: 'Rechtstreeks investeren',
    links,
  };
});

const fundLinks = computed(() => {
  const links: NavigationLink[] = [];
  // added to solve issue where computed is not triggered on time
  const isMobileMenuOpen = isMobileMenuVisible.value;

  if (isFundInvestor.value) {
    links.push(
      {
        to: '/mogelijk-fonds',
        label: 'Actueel fonds & beleggingen',
        icon: 'briefcase',
      },
    );
  }

  return links.length > 0 ? {
    label: 'Fondsbeleggen',
    links,
  } : undefined;
});

const borrowerLinks = computed(() => {
  const links: NavigationLink[] = [];
  // added to solve issue where computed is not triggered on time
  const isMobileMenuOpen = isMobileMenuVisible.value;

  if (isIosOrAndroid() && isVisibleForBorrowerInApp.value) {
    links.push(
      {
        to: '/hypotheken',
        label: 'Hypotheken',
        icon: 'hand-holding-dollar',
      },
      {
        to: '/hypotheek-overzicht',
        label: 'Hypotheekoverzicht',
        icon: 'display-chart-up',
      },
    );
  }

  return links.length > 0 ? {
    label: 'Ondernemershypotheken',
    links,
  } : undefined;
});

const partnerplanLinks = computed(() => {
  const links: NavigationLink[] = [];
  // added to solve issue where computed is not triggered on time
  const isMobileMenuOpen = isMobileMenuVisible.value;

  if (user.value?.is_mogelijk_partner) {
    links.push(
      {
        to: '/mijn-partnerplan',
        label: 'Partnerplan',
        icon: 'user-group-crown',
      },
    );
  }

  return links.length > 0 ? {
    label: 'Partnerplan',
    links,
  } : undefined;
});


const mobileLinks = computed(() => {
  const links: NavigationLink[] = [];
  // added to solve issue where computed is not triggered on time
  const isMobileMenuOpen = isMobileMenuVisible.value;

  if (user.value && isIosOrAndroid()) {
    links.push(
      {
        to: '/instellingen',
        label: 'Instellingen',
        icon: 'cog',
      },
    );
  }

  links.push(
    {
      to: 'callMeBack',
      label: 'Bel mij terug',
      icon: 'phone-arrow-down-left',
    },
    {
      to: 'handleiding',
      label: 'Handleiding',
      icon: 'circle-info',
    },
  );

  return links;
});

const linksToShow = computed(() => {
  const links = [
    ...investorLinks.value.links,
    ...(fundLinks.value ? fundLinks.value.links : []),
    ...(borrowerLinks.value ? borrowerLinks.value.links : []),
    ...(partnerplanLinks.value ? partnerplanLinks.value.links : []),
  ];

  return links;
});

const linksToShowOnMobile = computed(() => {
  let links = [investorLinks.value];
  // added to solve issue where computed is not triggered on time
  const isMobileMenuOpen = isMobileMenuVisible.value;

  links = fundLinks.value ? [...links, fundLinks.value] : links;
  links = borrowerLinks.value ? [...links, borrowerLinks.value] : links;
  links = partnerplanLinks.value ? [...links, partnerplanLinks.value] : links;

  return links;
});

const isOnboardingBannerShown = computed(() => isProfessionalismTestInExperienced.value || isProfessionalismTestRejected.value || isProfessionalismTestPendingRequest.value);
const isUserInvestor = computed(() => authenticated.value && isInvestor.value);

const getInitialCallBackValue = () => {
  const phoneNumbers = getUserPhoneNumbers();

  return {
    name: user.value ? user.value.name : '',
    phone: phoneNumbers && phoneNumbers.length > 0 ? phoneNumbers[0] : '',
    callback_date: '',
    callback_time: '08:30',
    via: route ? route.meta.callMeBack as string : '',
  };
};

const callBack = ref<CallBack>();
const isCompleted = ref(false);

const { executeMutation: createCallBack, fetching: isFetching } = useMutation(CREATE_CALLBACK);

const requestCall = async (callback: CallBack) => {
  callBack.value = callback;
  let toast;
  const { data } = await createCallBack({ input: callback });

  if (data) {
    toast = await useToast({
      title: 'Succes',
      message: 'Terugbelverzoek succesvol ingediend',
      theme: 'success',
    });
  } else {
    toast = await useToast({
      title: 'Er is iets fout gegaan',
      message: 'Probeer het later opnieuw',
      theme: 'error',
    });
  }

  isCompleted.value = true;
  toast.present();
};

const setCallMeBackModal = () => {
  isCompleted.value = false;

  useModal({
    title: 'Terugbelverzoek',
    component: CallMeBack,
    props: {
      isFetching,
      isCompleted,
      modelValue: getInitialCallBackValue(),
    },
    events: {
      createCallBack: (callback: CallBack) => requestCall(callback),
    },
  });
};

const setOfferInfoModal = () => {
  useModal({
    title: "Hoe gaat het in z'n werk?",
    component: OfferInfo,
  });
};

const handleRoute = (to: string) => {
  router.push(to);

  isMobileMenuVisible.value = false;
};

const handleMobileLink = (action: string) => {
  switch (action) {
    case 'callMeBack':
      setCallMeBackModal();
      break;
    case 'handleiding':
      setOfferInfoModal();
      break;
    default:
      handleRoute(action);
  }

  isMobileMenuVisible.value = false;
};

const totalNotificationCount = computed(() => {
  let count = 0;

  for (const item of linksToShow.value) {
    if (item.notificationCount?.value) {
      count += item.notificationCount.value;
    }
  }

  return count;
});

const isScrollUp = ref(true);

window.addEventListener('wheel', (event: WheelEvent) => {
  isScrollUp.value = (event.deltaY < 0);
});
</script>

<template>
  <div>
    <NuxtLayout name="default">
      <div v-if="isLoading" class="w-full h-screen grid place-items-center">
        <MogelijkLoader class="!w-40 h-auto pb-40" />
      </div>
      <div class="mx-auto flex w-full grow flex-col 3xl:max-w-screen-2xl">
        <OnboardingBanner v-if="isUserInvestor && isOnboardingBannerShown" />
        <PageHeader
          :notification-count="totalNotificationCount"
          :title="title"
          :show-timer="showTimer"
          :back-button-link="backButtonLink"
          @set-menu-visible="() => {isMobileMenuVisible = true}"
        >
          <template #hamburger>
            <MobileMenu
              :items="linksToShowOnMobile"
              :route="route.path"
              :is-menu-open-mobile="isMobileMenuVisible"
              class="tablet:hidden"
              @to="handleRoute"
              @logout="logout"
              @open-or-close-menu="isMobileMenuVisible = $event"
            >
              <div class="mb-8 z-20 rounded-2xl bg-white tablet:hidden" data-v-step-mobile="2">
                <MyAccount button-id="my-account-button-mobile" />
              </div>
              <template #submenu>
                <p v-if="user" class="font-semibold text-blue-500 text-sm pb-4">Mijn account</p>
                <ul class="flex flex-col gap-4 text-blue-700 text-base">
                  <li
                    v-for="link in mobileLinks"
                    :key="link.label"
                    class="flex gap-4 items-center"
                    @click="handleMobileLink(link.to)"
                  >
                    <FontAwesomeIcon
                      class="relative z-10 h-auto w-5"
                      :icon="['fal', link.icon]"
                    />
                    {{ link.label }}
                  </li>
                </ul>
              </template>
            </MobileMenu>
          </template>
        </PageHeader>
        <div v-show="!isMobileMenuVisible" class="flex w-full grow px-4 tablet:gap-4">
          <!-- top 102px is the space for the header and 183px is the height of the footer + margin of the cards in the fund page -->
          <div v-if="user" class="fixed inset-y-0 z-40 h-[calc(100vh-183px)] tablet:sticky tablet:top-[102px]" :class="{'inset-x-0 !z-50': isMobileMenuVisible}">
            <SidebarMenu
              :items="linksToShow"
              :route="route.path"
              @to="handleRoute"
              @logout="logout"
            />
          </div>
          <main id="content" class="relative flex w-full grow flex-col pt-4 lg:pt-0">
            <InstallPwaPopup v-if="isIosOrAndroidButNotPwa()" />
            <div class="flex grow">
              <slot />
              <Contact class="fixed right-4 bottom-20 z-20" />
            </div>
          </main>
        </div>
      </div>
    </NuxtLayout>
  </div>
</template>
